import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import axios from 'axios';
import styled from 'styled-components';
import Text from 'components/Text';
import { Loader, LoadingPage, PageContainer, Seo, TextInput } from 'components';
import Header from 'components/Header';
import { mobile, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from 'config';
import Button from 'components/PrimaryButton';
import { usePageView, useQuizData } from 'utils/hooks';
import { Quiz } from 'types/quiz';
import RegisterCompleted from './components/RegisterCompleted';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';

interface FormData {
  email: string;
  emailConfirm: string;
  password?: string;
  repeatedPassword?: string;
}

const defaultValues: FormData = {
  email: '',
  emailConfirm: '',
  password: undefined,
  repeatedPassword: undefined,
};

const validateSchema = z
  .object({
    email: z
      .string({ required_error: 'Please enter your email' })
      .email('Please enter a valid email address'),
    emailConfirm: z.string(),
    password: z
      .string({ required_error: 'Please enter your password' })
      .min(6, 'Password must be no shorter than 6 symbols'),
    repeatedPassword: z.string({
      required_error: 'Please confirm your password',
    }),
  })

  .refine(data => data.password === data.repeatedPassword, {
    message: 'Confirm password do not match with your password',
    path: ['repeatedPassword'],
  });

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 1rem;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 1.5rem;
  @media ${mobile} {
    margin: unset;
    align-items: unset;
    margin-top: 2rem;
  }
`;

const Title = styled(Text)`
  margin-bottom: 1.5rem;
  width: 100%;
  @media ${mobile} {
    margin-bottom: 0.75rem;
  }
`;

const Subtitle = styled(Text)`
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  max-width: 560px;
  padding: 1.5rem 0;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${mobile} {
    width: 100%;
    max-width: 100%;
    border: none;
    border-radius: 0px;
  }
`;

const StyledButton = styled(Button)`
  max-width: 560px;
  width: 100%;
  background: ${({ theme }) => theme.colors.orange};
  border-radius: 100px;
  @media ${mobile} {
    width: 100%;
    margin-bottom: 32px;
  }
`;

const StyledTextInput = styled(TextInput)`
  border: 1px solid #e4e4eb;
  border-radius: 100px;
`;

const FormContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
  @media ${mobile} {
  }
`;

const Register: FC = () => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useQuery();

  const [errors, setErrors] =
    useState<Partial<Record<keyof FormData, string>>>();
  const [formValues, setFormValues] = useState<FormData>(defaultValues);
  const [isRegisterComplete, setIsRegisterComplete] = useState<boolean>(false);

  const { quiz_answers, user, code, selected_plans, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const upgradeSequences = useQuizData('upgradeSequences');

  const data = useQuizData('register');

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    client_code: code,
  });

  const { goToUpgrade, goToSuccess } = useRouter();

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  useEffect(() => {
    if (user) {
      formValues.email = user.email;
      formValues.emailConfirm = user.email;
      setFormValues(formValues);
    }
  }, [user]);

  const handleRegister = async (data: FormData) => {
    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    const userData = {
      email: data.email,
      password: data.password,
    };

    const funnelData = {
      code: code ?? '',
      quizAnswers: quiz_answers ?? {},
      selectedPlans: selected_plans ?? {},
      quiz,
      onboardingBooked: true,
    };
    // goToUpgrade(Object.keys(upgradeSequences[selected_plan.key])[0]);

    try {
      await axios.post(config.FIREBASE_REGISTER_URL, {
        user: userData,
        funnel: funnelData,
      });

      Tracking.trackRegistration(funnelData.code);

      // klaviyoLogUserHasPaidAndRegistered(data.email);
      // if user came from support email - prevent going to upgrade sequence
      if (isFromSupportCase) {
        setIsRegisterComplete(true);
        setLoading(false);
        return;
      }
      if (!upgradeSequences) {
        goToSuccess();
      }

      // goToUpgrade(Object.keys(upgradeSequences[selected_plan.key])[0]);
    } catch (error) {
      console.error(error);

      setLoading(false);
    }
    goToSuccess();
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.scrollIntoView();
  };

  const validateInputs = async (data: FormData) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse(data);
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const validateInput = async (data: Partial<FormData>, field: string) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse({
        ...formValues,
        [field]: data[field as keyof FormData],
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          if (e.path[0] !== field) return;
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    const isEmpty = Object.keys(validationErrors).length === 0;
    if (isEmpty) {
      return setErrors({ ...errors, [field]: null });
    }
    return setErrors({ ...errors, ...validationErrors });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = await validateInputs(formValues);
    if (Object.keys(validationErrors).length === 0) {
      handleRegister(formValues);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    await validateInput(
      { [event.target.name]: event.target.value },
      event.target.name,
    );
  };

  const renderBottomComponent = () => {
    if (loading) return <Loader wrapperHeight={'10'} />;
    if (isRegisterComplete) return <RegisterCompleted />;
    return <StyledButton type="submit">{data?.submitBtnTitle}</StyledButton>;
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo title="Dog Training Book | Raising Dog" />
      <Header logoVariant="center" />
      <PageContainer with100vh={true} bgColor={data.bgColor}>
        <ContentContainer>
          <Title type={isTablet ? 'h3' : 'h1S600'} color="dark100">
            {data?.title}
          </Title>
          <Subtitle type="h4400">{data?.subtitle}</Subtitle>

          <Form onSubmit={handleSubmit}>
            <FormContentContainer>
              <InputWrapper>
                <StyledTextInput
                  name="email"
                  defaultValue={user?.email ?? ''}
                  label={data?.emailLabel}
                  error={errors?.email}
                  disabled={true}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <StyledTextInput
                  name="emailConfirm"
                  defaultValue={user?.email ?? ''}
                  label={data?.confirmEmailLabel}
                  error={errors?.emailConfirm}
                  disabled={true}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <StyledTextInput
                  type="password"
                  name="password"
                  label={data?.passwordLabel}
                  error={errors?.password}
                  disabled={loading}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  placeholder="Enter here"
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <StyledTextInput
                  type="password"
                  name="repeatedPassword"
                  label={data?.confirmPasswordLabel}
                  error={errors?.repeatedPassword}
                  disabled={loading}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  placeholder="Enter here"
                  required
                />
              </InputWrapper>
            </FormContentContainer>
            {renderBottomComponent()}
          </Form>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const InputWrapper = styled.div`
  margin-top: 0.6rem;
`;

export default Register;
